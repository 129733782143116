import React, { useEffect, useState } from "react";
import maleIcon from "../../assets/images/male-icon.png";
import femaleIcon from "../../assets/images/female-icon.png";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Loading from "./components/Loading";

const Stat = ({ data }) => {
  return (
    <div className="col-span-2">
      <p className="border p-3 px-3 rounded-t-xl bg-base-100 text-white flex justify-between">
        <span className="self-center">{data.title}</span>
        {
          !data.rank == 'N/A' &&
        <span className="font-bold text-base-100 bg-lime-500 rounded-full w-[25px] aspect-square flex justify-center text-xs">
          <span className=" text=xs text-center self-center">{data.rank}</span>
        </span>
        }
      </p>

      {data.stats.map((stat, index) => {
        return (
          <p
            key={index}
            className="border p-2 px-3  text-base-100  flex justify-between text-sm"
          >
            <span>{stat.title}</span>{" "}
            <span className="font-bold text-xs">{stat.count}</span>
          </p>
        );
      })}
    </div>
  );
};

export default function PlayerDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const base_url = useSelector((state) => state.config.base_url);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  const [loading, setLoading] = useState(true);
  const [playerData, setPlayerData] = useState(null);

  const [name, setName] = useState(["Owolana", "Ayomide"]);
  const [position, setPosition] = useState("Defender");
  const [team, setTeam] = useState("Manchester United");
  const [jersey, setJersey] = useState("");

  const [coreStats, setCoreStats] = useState([]);

  const [stats, setStats] = useState([]);

  useEffect(() => {
    const path = location.pathname.split("/");
    const url = path[1];
    const id = path[3];

    console.log(url, id);

    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    fetch(
      `${base_url}api/get_player_stats.php?url=${url}&player=${id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setPlayerData(data);

        setName(data.player_data.name.split(" "));
        setPosition(data.player_data.position);
        setTeam(data.player_data.team);
        setJersey(data.player_data.jersey);

        const coreStatHeaders = Object.keys(data.core_stats);
        let coreStatData = [];

        coreStatHeaders.forEach((item) => {
          let res = data.core_stats[item];
          coreStatData.push(res);
        });

        setCoreStats(coreStatData);

        const otherStatHeaders = Object.keys(data.other_data);
        let otherStatData = [];
        otherStatHeaders.forEach((item) => {
          let res = {};
          let statsToShow = [];
          let stats = Object.keys(data.other_data[item].stats);

          let stats_arr = [];

          stats.forEach((stat) => {
            statsToShow.push(data.other_data[item].stats[stat]);
          });

          otherStatData.push({
            title: data.other_data[item].title,
            rank: data.other_data[item].rank,
            stats: statsToShow,
          });
        });
        console.log(otherStatData);

        setStats(otherStatData);

        setLoading(false);
      })
      .catch((err) => {
        alert("Error getting player data");
        navigate(-1)
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loading text={"Loading Player Details"} />
      ) : (
        <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto md:grid grid-cols-6 gap-4">
          <div className="bg-gray-100 p-6  col-span-2 rounded-lg shadow-lg">
            <img
              className="w-full max-w-full aspect-square"
              src={maleIcon}
              alt=""
            />
            <div className=" w-full flex flex-col justify-start gap-4">
              <div className="flex justify-between mt-3">
                <p className="text-base-100 font-bold">
                  <span className="text-2xl">{name[0] && name[0]}</span>
                  <br />
                  <span className="text-4xl">{name[1] && name[1]}</span>
                </p>

                <p className="text-5xl text-base-100 font-extrabold">
                  {jersey}
                </p>
              </div>
              <div>
                <p className="text-base text-base-100">{team}</p>
                <p className="text-base text-base-100"> <span className="font-bold">Position:</span> {position}</p>
              </div>
            </div>
          </div>

          <div className=" col-span-4">
            <div className=" my-5 md:my-0 grid grid-cols-2  md:grid-cols-4 gap-2">
              {coreStats.map((stat, index) => {
                return (
                  <div className="border p-3 rounded-lg" key={index}>
                    <p className="text-base-100">{stat.title}</p>
                    <p className="text-4xl font-bold">{stat.count}</p>
                  </div>
                );
              })}
            </div>

            <div className="grid md:grid-cols-4 p-2 mt-3 gap-4 md:gap-2">
              {stats.map((stat, index) => {
                return <Stat key={index} data={stat} />;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
